
const jsonConfig = {
    urlReact: 'https://areadoassociado.sinprev.org.br',
    urlAPI: 'https://app.sinprev.org.br/associado',
    APIKEY: "Bearer eyJhbGciOiJIUzI1NiJ9.TEFTaGRvYXNuaGRvaXVhc2RvQSY2c2Q5JsOCUypkbmFvc2RodW1waXNhZMOhc2RsYXVzZComOTc2OTdhc2Rhc3VkaDDDoXNkw4RTRE1hczh5blBBc2RQQSZzeWRtcGFzaGQsYXNvZGFzZG5hc2lQQTh1c2RwYXN1PkFzZC5Bc2pkYXNwZCxdfX1dKX19XSlsa2FqaHNnbmRPKiYoJiooN21hc2Q.iVHGvPh9Ch7UyOvd2kd0kfrFnc5mVLLUoCiIEygY5mU",
    limitSearch: 10

}


export { jsonConfig }