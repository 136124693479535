import { createContext, useContext } from "react";
import { AuthContext } from ".";
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';
// import { useParams } from 'react-router-dom';
import { conection } from '../utils/index'
import moment from "moment";


export const AuthContextApi = createContext();


const AuthProvider = ({ children }) => {

    const { states, setters } = useContext(AuthContext);
    const axiosAuth = conection.auth();
    const axiosNoAuth = conection.noAuth();

    const loginStep1 = async () => {
        try {
            setters.setIsLoading(true)
            const response = await axiosNoAuth.post('/login/step1', { cpf: states.cpfInput })
            setters.setAssociate_id(response.data.data.associate_id)
            setters.setName(response.data.data.name)
            setters.setStep1(false)
            setters.setStep2(true)
            setters.setIsComplete(response.data.complete)
            setters.setIsLoading(false)
            return true;
        } catch (err) {
            //console.log(err)
            setters.setIsLoading(false)
            toast.error(err.response?.data?.msg || 'Erro ao tentar fazer login')
            return false;
        }
    }

    const loginStep2 = async () => {
        try {
            setters.setIsLoading(true)
            const response = await axiosNoAuth.post('/login/step2', { cpf: states.cpfInput, password: states.passwordInput, associate_id: states.associate_id })
            //console.log(response.data)
            setters.setIsLoading(false)
            setters.setSigned(true)
            setters.setStep1(true)
            setters.setStep2(false)
            setters.setIsComplete(false)
            Cookies.set('token', response.data.token, { expires: moment().add(1, 'day').toDate() })
            return true;
        } catch (err) {

            Cookies.remove('token')
            setters.setIsLoading(false)
            setters.setSigned(false)
            setters.setStep1(true)
            setters.setStep2(false)
            setters.setIsComplete(false)
            toast.error(err.response?.data?.msg || 'Erro ao tentar fazer login')
            return false;
        }
    }

    const forgotPassword = async () => {
        try {
            setters.setIsLoading(true)
            const response = await axiosNoAuth.post('/login/forgot', { cpf: states.cpfInput })
            setters.setSigned(false)
            setters.setStep1(true)
            setters.setStep2(false)
            setters.setIsComplete(false)
            setters.setIsLoading(false)
            toast.success(response.data.msg)
            return true;
        } catch (err) {

            setters.setIsLoading(false)
            setters.setSigned(false)
            setters.setStep1(true)
            setters.setStep2(false)
            setters.setIsComplete(false)
            toast.error(err.response?.data?.msg || 'Erro ao solicitar recuperação de senha')
            return false;
        }

    }

    const resetPassword = async () => {
        try {
            setters.setIsLoading(true)
            const response = await axiosNoAuth.post('/login/recovery', { password: states.passwordInput, token: states.tokenForgotPassword, newPassword: states.confirmPasswordInput })
            // 
            setters.setSigned(false)
            setters.setStep1(true)
            setters.setStep2(false)
            setters.setIsComplete(false)
            setters.setIsLoading(false)            //ççç
            toast.success(response.data.msg)
            return true;
        } catch (err) {
            // 
            setters.setIsLoading(false)
            setters.setSigned(false)
            setters.setStep1(true)
            setters.setStep2(false)
            setters.setIsComplete(false)
            toast.error(err.response?.data?.msg || 'Erro ao tentar recuperar senha')
            return false;
        }

    }

    const checkLogin = async () => {
        try {
            await axiosAuth.get('/user/check')
            return true
        } catch (err) {
            if (err.response?.status === 403) {
                setters.setSigned(false)
                Cookies.remove('token')
                return false;
            }
            toast.error(err.response?.data?.msg || 'Login expirado')
            return false;
        }
    }


    const getFaqs = async () => {
        try {
            setters.setIsLoading(true)
            const response = await axiosAuth.get('/associate/faq')
            setters.setFaqs(response.data.data)
            setters.setIsLoading(false)
            return true;
        } catch (err) {
            if (err.response?.status === 403) {
                setters.setSigned(false)
                Cookies.remove('token')
                return false;

            }
            toast.error(err.response?.data?.msg || 'Erro ao buscar perguntas frequentes')
            setters.setIsLoading(false)
            return false;
        }
    }

    const getNews = async () => {
        try {
            setters.setIsLoading(true)
            const response = await axiosAuth.get(`/news/user/getWithLimit/${states.limitNews}&${states.offsetNews}`)
            if (states.dataNews.length > 0) {
                const dataOutArr = response.data.data.filter(item => !states.dataNews.some(item2 => item2.id === item.id))
                if (dataOutArr.length > 0) {
                    setters.setDataNews([...states.dataNews, ...dataOutArr])
                }
                // setters.setDataNews([...response.data.data, ...states.dataNews])
            } else {
                setters.setDataNews(response.data.data)
            }
            setters.setHaveMoreNews(response.data.haveMore)
            setters.setIsLoading(false)
            return true;
        } catch (err) {
            if (err.response?.status === 403) {
                setters.setSigned(false)
                setters.setIsLoading(false)
                Cookies.remove('token')
                return false;
            }
            toast.error(err.response?.data?.msg || 'Erro ao buscar noticias')
            setters.setIsLoading(false)
            setters.setLimitSearchNews(true)
            states.offsetNews > 0 && setters.setOffsetNews(states.offsetNews - states.limitNews)
            states.offsetNews <= 0 && setters.setOffsetNews(0)
            return false;
        }
    }

    const getCreditCard = async () => {
        try {
            setters.setIsLoading(true)
            const response = await axiosAuth.get('/credit/card')
            setters.setDataCards(response.data.data)
            setters.setIsLoading(false)
            return true;
        } catch (err) {
            if (err.response?.status === 403) {
                setters.setSigned(false)
                Cookies.remove('token')
                return false;
            }
            toast.error(err.response?.data?.msg || 'Erro ao buscar cartão de crédito')
            setters.setIsLoading(false)
            return false;
        }
    }

    const deleteCreditCard = async () => {
        try {
            setters.setIsLoading(true)
            const response = await axiosAuth.put('/credit/card', { id: states.Card.id })
            setters.setIsLoading(false)
            setters.setCard({})
            toast.success(response.data.msg)
            getCreditCard()
            return true;
        } catch (err) {
            if (err.response?.status === 403) {
                setters.setSigned(false)
                Cookies.remove('token')
                return false;
            }
            toast.error(err.response?.data?.msg || 'Erro ao deletar cartão de crédito')
            setters.setIsLoading(false)
            return false;
        }
    }

    const addCreditCard = async (data) => {
        try {
            setters.setIsLoading(true)
            const response = await axiosAuth.post('/credit/card', data)
            setters.setIsLoading(false)
            setters.setCard({})
            toast.success(response.data.msg)
            getCreditCard()
            return true;
        } catch (err) {

            if (err.response?.status === 403) {
                setters.setSigned(false)
                Cookies.remove('token')
                return false;
            }
            toast.error(err.response?.data?.msg || 'Erro ao adicionar cartão de crédito')
            setters.setIsLoading(false)
            return false;
        }
    }


    const getInvoices = async () => {
        try {
            setters.setIsLoading(true)
            const response = await axiosAuth.get(`/invoices/getWithLimit/${states.limitFaturas}&${states.offsetFaturas}`)
            if (states.dataFaturas.length > 0) {
                const dataOutArr = response.data.data.filter(item => !states.dataFaturas.some(item2 => item2.id === item.id))
                if (dataOutArr.length > 0) {
                    setters.setDataFaturas([...states.dataFaturas, ...dataOutArr])
                }
            } else {
                setters.setDataFaturas(response.data.data)
            }
            setters.setHaveMoreFaturas(response.data.data.length === states.limitFaturas ? true : false)
            setters.setIsLoading(false)
            return true;
        } catch (err) {
            if (err.response?.status === 403) {
                setters.setSigned(false)
                Cookies.remove('token')
                return false;
            }
            toast.error(err.response?.data?.msg || 'Erro ao buscar faturas')
            setters.setIsLoading(false)
            setters.setLimitSearchFaturas(true)
            states.offsetFaturas > 0 && setters.setOffsetFaturas(states.offsetFaturas - states.limitFaturas)
            states.offsetFaturas <= 0 && setters.setOffsetFaturas(0)
            return false;
        }
    }

    const payedInvoice = async (data) => {
        try {
            setters.setIsLoading(true)
            const response = await axiosAuth.post('/invoices/pay', data)
            setters.setIsLoading(false)
            setters.setFatura({})
            toast.success(response.data.msg)
            await getInvoices()
            return true;
        } catch (err) {


            if (err.response?.status === 403) {
                setters.setSigned(false)
                Cookies.remove('token')
                return false;
            }
            toast.error(err.response?.data?.msg || 'Erro ao pagar fatura')
            setters.setFatura({})
            setters.setIsLoading(false)
            return false;
        }
    }

    const getSignature = async () => {
        try {
            setters.setIsLoading(true)
            const response = await axiosAuth.get('/signature')
            setters.setSignatgure(response.data.data)
            setters.setIsLoading(false)
            return true;
        } catch (err) {
            if (err.response?.status === 403) {
                setters.setSigned(false)
                Cookies.remove('token')
                return false;
            }
            toast.error(err.response?.data?.msg || 'Erro ao buscar assinatura')
            setters.setIsLoading(false)
            return false;
        }
    }

    const changeMethodSignature = async () => {
        try {
            setters.setIsLoading(true)
            const response = await axiosAuth.put('/signature', states.Signatgure)
            setters.setIsLoading(false)
            toast.success(response.data.msg)
            getSignature()
            return true;
        } catch (err) {
            if (err.response?.status === 403) {
                setters.setSigned(false)
                Cookies.remove('token')
                return false;
            }
            toast.error(err.response?.data?.msg || 'Erro ao alterar assinatura')
            setters.setIsLoading(false)
            return false;
        }
    }


    const getJuridico = async () => {
        try {
            setters.setIsLoading(true)
            const response = await axiosAuth.get(`/process/getWithLimit/${states.limitJuridico}&${states.offsetJuridico}`)
            if (states.dataJuridico.length > 0) {
                const dataOutArr = response.data.data.filter(item => !states.dataJuridico.some(item2 => item2.id === item.id))
                if (dataOutArr.length > 0) {
                    setters.setDataJuridico([...states.dataJuridico, ...dataOutArr])
                }
            } else {
                setters.setDataJuridico(response.data.data)
            }
            setters.setHaveMoreJuridico(response.data.data.length === states.limitJuridico ? true : false)
            setters.setIsLoading(false)
            return true;
        } catch (err) {
            if (err.response?.status === 403) {
                setters.setSigned(false)
                Cookies.remove('token')
                return false;
            }
            toast.error(err.response?.data?.msg || 'Erro ao buscar processos')
            setters.setIsLoading(false)
            setters.setLimitSearchJuridico(true)
            states.offsetJuridico > 0 && setters.setOffsetJuridico(states.offsetJuridico - states.limitJuridico)
            states.offsetJuridico <= 0 && setters.setOffsetJuridico(0)
            return false;
        }
    }


    const getJuridicoProcesso = async (id) => {
        try {
            setters.setIsLoading(true)
            const response = await axiosAuth.get(`/process/show/${id}/${states.limitProcessos}&${states.offsetProcessos}`)
            if (Object.keys(states.dataProcessos).length > 0) {
                const updatesOutState = [];
                const motionsOutState = [];
                if (response.data.data.updates.length === 0 && response.data.data.motions.length === 0) {
                    toast.info('Não há atualizações')
                    setters.setIsLoading(false)
                    setters.setLimitSearchProcessos(true)
                    return;
                }
                if (response.data.data.updates.length > 0) {
                    response.data.data.updates.forEach(item => {
                        if (!states.dataProcessos.updates.some(item2 => item2.id === item.id)) {
                            updatesOutState.push(item)
                        }
                    })
                }
                if (response.data.data.motions.length > 0) {
                    response.data.data.motions.forEach(item => {
                        if (!states.dataProcessos.motions.some(item2 => item2.id === item.id)) {
                            motionsOutState.push(item)
                        }
                    })
                }
                setters.setDataProcessos({ ...states.dataProcessos, updates: [...states.dataProcessos.updates, ...updatesOutState], motions: [...states.dataProcessos.motions, ...motionsOutState] })
            } else {
                setters.setDataProcessos(response.data.data)
            }
            setters.setIsLoading(false)
            return true;
        } catch (err) {
            if (err.response?.status === 403) {
                setters.setSigned(false)
                setters.setIsLoading(false)
                Cookies.remove('token')
                return false;
            }
            toast.error(err.response?.data?.msg || 'Erro ao buscar processo')
            setters.setIsLoading(false)
            setters.setLimitSearchProcessos(true)
            states.offsetProcessos > 0 && setters.setOffsetProcessos(states.offsetProcessos - states.limitProcessos)
            states.offsetProcessos <= 0 && setters.setOffsetProcessos(0)
            return false;
        }
    }


    const getProfile = async () => {
        try {
            setters.setIsLoading(true)
            const response = await axiosAuth.get('/user/profile')
            setters.setProfile(response.data.data)
            setters.setIsLoading(false)
            return true;
        } catch (err) {
            if (err.response?.status === 403) {
                setters.setSigned(false)
                Cookies.remove('token')
                return false;
            }
            toast.error(err.response?.data?.msg || 'Erro ao buscar perfil')
            setters.setIsLoading(false)
            return false;
        }
    }

    const updateProfile = async () => {
        try {
            setters.setIsLoading(true)
            const response = await axiosAuth.put('/user/profile', states.Profile)
            setters.setIsLoading(false)
            toast.success(response.data.msg)
            getProfile()
            return true;
        } catch (err) {

            if (err.response?.status === 403) {
                setters.setSigned(false)
                Cookies.remove('token')
                return false;
            }
            toast.error(err.response?.data?.msg || 'Erro ao atualizar perfil')
            setters.setIsLoading(false)
            return false;
        }
    }

    const logout = async () => {
        try {
            await axiosAuth.get('/login/logout')
        } catch (err) {


        }
        setters.setSigned(false)
        Cookies.remove('token')
    }


    const getDocsOfficiais = async () => {
        try {
            setters.setIsLoading(true)
            const response = await axiosAuth.get(`/officialDocuments`)
            setters.setDataDocs(response.data.data)
            setters.setIsLoading(false)
            return true;
        } catch (err) {
            if (err.response?.status === 403) {
                setters.setSigned(false)
                Cookies.remove('token')
                return false;
            }
            toast.error(err.response?.data?.msg || 'Erro ao buscar documentos oficiais')
            setters.setIsLoading(false)
            return false;
        }
    }

    const getDocsOfficiaisAgo = async () => {
        try {
            setters.setIsLoading(true)
            const response = await axiosAuth.get(`/officialDocumentsAgo`)
            setters.setDataDocsAgo(response.data.data)
            setters.setIsLoading(false)
            return true;
        } catch (err) {
            if (err.response?.status === 403) {
                setters.setSigned(false)
                Cookies.remove('token')
                return false;
            }
            toast.error(err.response?.data?.msg || 'Erro ao buscar documentos oficiais')
            setters.setIsLoading(false)
            return false;
        }
    }

    const getDocsOfficiaisInformativo = async () => {
        try {
            setters.setIsLoading(true)
            const response = await axiosAuth.get(`/officialDocumentsInformativo`)
            setters.setDataDocsAgo([response.data.data])
            setters.setIsLoading(false)
            return true;
        } catch (err) {
            if (err.response?.status === 403) {
                setters.setSigned(false)
                Cookies.remove('token')
                return false;
            }
            toast.error(err.response?.data?.msg || 'Erro ao buscar documentos oficiais')
            setters.setIsLoading(false)
            return false;
        }
    }


    return (
        <AuthContextApi.Provider
            value={{
                getJuridicoProcesso,
                getDocsOfficiaisInformativo,
                getDocsOfficiaisAgo,
                getDocsOfficiais,
                logout,
                updateProfile,
                getProfile,
                getJuridico,
                changeMethodSignature,
                getSignature,
                payedInvoice,
                getInvoices,
                addCreditCard,
                deleteCreditCard,
                getCreditCard,
                getNews,
                getFaqs,
                checkLogin,
                loginStep1,
                loginStep2,
                forgotPassword,
                resetPassword
            }}>
            {children}
        </AuthContextApi.Provider>
    )
}

export default AuthProvider;
