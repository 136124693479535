import { createContext, useState } from "react";
import { jsonConfig } from "../Certs";

export const AuthContext = createContext();

const AuthProvider = ({ children }) => {

    const [signed, setSigned] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const [cpfInput, setCpfInput] = useState('')
    const [passwordInput, setPasswordInput] = useState('')
    const [confirmPasswordInput, setConfirmPasswordInput] = useState('')
    const [associate_id, setAssociate_id] = useState('')
    const [isComplete, setIsComplete] = useState(false)
    const [name, setName] = useState('')

    const [errorCpf, setErrorCpf] = useState(false)
    const [errorPassword, setErrorPassword] = useState(false)
    const [errorConfirmPassword, setErrorConfirmPassword] = useState(false)

    const [forgotPassword, setForgotPassword] = useState(false)
    const [tokenForgotPassword, setTokenForgotPassword] = useState('')

    const [sideBar, setSideBar] = useState(false)

    const [step1, setStep1] = useState(true)
    const [step2, setStep2] = useState(false)

    const [faqs, setFaqs] = useState([])

    const [dataNews, setDataNews] = useState([])
    const [News, setNews] = useState({})
    const [limitNews, setLimitNews] = useState(jsonConfig.limitSearch)
    const [offsetNews, setOffsetNews] = useState(0)
    const [limitSearchNews, setLimitSearchNews] = useState(false)
    const [haveMoreNews, setHaveMoreNews] = useState(true)

    const [dataCards, setDataCards] = useState([])
    const [Card, setCard] = useState({})

    const [modalConfirm, setModalConfirm] = useState(false)
    const [modalCreditCard, setModalCreditCard] = useState(false)

    const [dataFaturas, setDataFaturas] = useState([])
    const [Fatura, setFatura] = useState({})
    const [offsetFaturas, setOffsetFaturas] = useState(0)
    const [limitFaturas, setLimitFaturas] = useState(jsonConfig.limitSearch)
    const [haveMoreFaturas, setHaveMoreFaturas] = useState(true)
    const [limitSearchFaturas, setLimitSearchFaturas] = useState(false)

    const [Signatgure, setSignatgure] = useState({})
    const [modalSignature, setModalSignature] = useState(false)


    const [dataJuridico, setDataJuridico] = useState([])
    const [offsetJuridico, setOffsetJuridico] = useState(0)
    const [limitJuridico, setLimitJuridico] = useState(jsonConfig.limitSearch)
    const [haveMoreJuridico, setHaveMoreJuridico] = useState(true)
    const [limitSearchJuridico, setLimitSearchJuridico] = useState(false)

    const [Profile, setProfile] = useState({})

    const [dataDocs, setDataDocs] = useState([])
    const [dataDocsAgo, setDataDocsAgo] = useState([])


    const [dataProcessos, setDataProcessos] = useState({})
    const [offsetProcessos, setOffsetProcessos] = useState(0)
    const [limitProcessos, setLimitProcessos] = useState(jsonConfig.limitSearch)
    const [limitSearchProcessos, setLimitSearchProcessos] = useState(false)


    const states =
    {
        limitSearchProcessos,
        dataProcessos,
        offsetProcessos,
        limitProcessos,
        dataDocsAgo,
        dataDocs,
        Profile,
        dataJuridico,
        offsetJuridico,
        limitJuridico,
        haveMoreJuridico,
        limitSearchJuridico,
        modalSignature,
        Signatgure,
        dataFaturas,
        Fatura,
        offsetFaturas,
        limitFaturas,
        haveMoreFaturas,
        limitSearchFaturas,
        modalCreditCard,
        modalConfirm,
        dataCards,
        Card,
        haveMoreNews,
        dataNews,
        News,
        limitNews,
        offsetNews,
        limitSearchNews,
        faqs,
        sideBar,
        tokenForgotPassword,
        forgotPassword,
        confirmPasswordInput,
        errorConfirmPassword,
        name,
        isComplete,
        associate_id,
        isLoading,
        signed,
        cpfInput,
        passwordInput,
        errorCpf,
        errorPassword,
        step1,
        step2


    }

    const setters =
    {
        setLimitSearchProcessos,
        setDataProcessos,
        setOffsetProcessos,
        setLimitProcessos,
        setDataDocsAgo,
        setDataDocs,
        setProfile,
        setDataJuridico,
        setOffsetJuridico,
        setLimitJuridico,
        setHaveMoreJuridico,
        setLimitSearchJuridico,
        setModalSignature,
        setSignatgure,
        setLimitSearchFaturas,
        setHaveMoreFaturas,
        setOffsetFaturas,
        setFatura,
        setDataFaturas,
        setLimitFaturas,
        setModalCreditCard,
        setModalConfirm,
        setDataCards,
        setCard,
        setHaveMoreNews,
        setDataNews,
        setNews,
        setLimitNews,
        setOffsetNews,
        setLimitSearchNews,
        setFaqs,
        setSideBar,
        setTokenForgotPassword,
        setForgotPassword,
        setConfirmPasswordInput,
        setErrorConfirmPassword,
        setName,
        setIsComplete,
        setAssociate_id,
        setIsLoading,
        setSigned,
        setCpfInput,
        setPasswordInput,
        setErrorCpf,
        setErrorPassword,
        setStep1,
        setStep2
    }


    const navigationJuridico = [
        { name: 'Painel', href: '/juridico', current: false },
        { name: 'Processos', href: '/juridico/processos', current: false },
        { name: 'Informativo', href: '/juridico/informativo', current: false }
    ]

    const navigationComunicacao = [
        { name: 'Painel', href: '/comunicacao', current: false },
        { name: 'Perguntas Frequentes', href: '/comunicacao/faq', current: false },
        { name: 'Últimas notícias', href: '/comunicacao/noticias', current: false },
        { name: 'AGO - Abril/2024', href: '/comunicacao/agoabril2024', current: false }
    ]

    const navigationFinanceiro = [
        { name: 'Painel', href: '/financeiro', current: false },
        { name: 'Assinatura', href: '/financeiro/assinatura', current: false },
        { name: 'Cartões', href: '/financeiro/cartoes', current: false },
        { name: 'Faturas', href: '/financeiro/faturas', current: false }
    ]

    const navigationDocs = [
        { name: 'Painel', href: '/documentosOficiais', current: false },
        { name: 'Relatórios Contábeis', href: '/documentosOficiais/contabilidade', current: false },
        { name: 'AGO - Abril/2024', href: '/documentosOficiais/agoabril2024', current: false }
    ]


    return (
        <AuthContext.Provider
            value={{
                states,
                setters,
                navigationComunicacao,
                navigationFinanceiro,
                navigationDocs,
                navigationJuridico
            }}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthProvider;
