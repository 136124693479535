import NavBar from '../../Components/NavBar'
import { AuthContextApi } from '../../Context/api'
import { AuthContext } from '../../Context'
import { useState, useContext, useEffect } from 'react'
import Footer from '../../Components/Footer'
import SpinnerOverlay from '../../Components/SpinnerOverlay'
import { EnvelopeOpenIcon, UsersIcon } from '@heroicons/react/24/outline'
import { useNavigate } from 'react-router-dom'
import News from '../../Components/NewsHome'


const stats = [
    { id: 1, name: 'Faturas', icon: UsersIcon, change: '122', changeType: 'increase', href: '/financeiro/faturas' },
    { id: 2, name: 'Processos', icon: EnvelopeOpenIcon, change: '5.4%', changeType: 'increase', href: '/juridico' }
]




const DashBoard = () => {
    const { checkLogin, getProfile, getNews, getFaqs } = useContext(AuthContextApi)
    const { states, setters } = useContext(AuthContext)
    const [cumprimento, setCumprimento] = useState('')
    const [safePay, setSafePay] = useState(false)
    const [insertCard, setInsertCard] = useState(false)

    const navigate = useNavigate()

    const clickButton = (href) => {
        navigate(href)
    }



    const isSigned = async () => {
        const response = await checkLogin()
        if (response === true) {
            setters.setSigned(true)
        } else {
            setters.setSigned(false)
        }
    }

    const loadAll = async () => {
        await Promise.all([getProfile(), getNews(), getFaqs()])
    }

    const newsGet = async () => {
        await getNews()
    }



    useEffect(() => {
        isSigned()
        const dataHora = new Date()
        const hora = dataHora.getHours()
        if (hora >= 1 && hora <= 11) {
            setCumprimento('Bom dia')
        }
        else if (hora >= 12 && hora <= 17) {
            setCumprimento('Boa tarde')
        }
        else if (hora >= 18 && hora <= 23) {
            setCumprimento('Boa noite')
        }
        else {
            setCumprimento('Olá')
        }
        if (states.dataNews.length === 0 || states.faqs.length === 0 || Object.keys(states.Profile).length === 0) {
            loadAll()
        }

    }, [])

    useEffect(() => {
        if (states.faqs.length === 0 || Object.keys(states.Profile).length === 0) {
            loadAll()
        }
    }, [states.faqs, states.Profile])

    useEffect(() => {
        if (states.dataNews.length === 0) {
            newsGet()
        }
    }, [states.dataNews])

    return (
        <>
            <div className="flex flex-1 flex-col pb-16 bg-white">
                <NavBar />
                <div className="flex flex-col flex-1 items-center justify-center">
                    <SpinnerOverlay />

                    <div className='flex flex-1 flex-col items-center md:pl-64 mb-4 mt-2 w-full rounded-[10px]'>
                        <div className="p-4 border w-[95%]">
                            <div className="px-4 sm:px-6 lg:px-8">
                                <h1 className="text-base font-semibold leading-6 text-gray-900">Dashboard</h1>
                                <p className="mt-2 text-sm text-gray-700">
                                    {cumprimento}, {states.Profile.name}
                                </p>
                                <p className="mt-2 text-sm text-gray-700">
                                    Aqui você terá as principais informações sobre a sua área do associado.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="flex flex-1 flex-col items-center md:pl-64  w-full rounded-[10px]">
                        <div className="p-4 w-[95%]">

                            <dl className=" grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
                                {stats.map((item) => (
                                    <button key={item.id} onClick={() => clickButton(item.href)}
                                        className="relative overflow-hidden rounded-lg bg-white px-4 pb-1 pt-1 shadow sm:px-5 sm:pt-1 hover:shadow-md border">
                                        <dt>
                                            <div className="absolute rounded-md bg-blue-500 p-3 mt-2">
                                                <item.icon className="h-5 w-5 text-white" aria-hidden="true" />
                                            </div>
                                        </dt>
                                        <dd className="ml-16 flex flex-col items-start pb-6 sm:pb-7">
                                            <p className="text-mlg font-semibold text-gray-900">{item.name}</p>
                                            <p className='ml-2 text-md  text-gray-900'>Clique para ver mais</p>
                                        </dd>
                                    </button>
                                ))}
                            </dl>
                        </div>
                    </div>

                    <div className="flex flex-1 flex-col items-center md:pl-64 w-full rounded-[10px]">
                        <div className="p-4 w-[100%]">
                            {
                                states.dataNews.length > 0 ? (
                                    <div className="px-4 sm:px-6 lg:px-8 p-8  ">
                                        <div className="px-4 sm:px-6 lg:px-8 border rounded-[10px] shadow p-8">
                                            <p className="text-base font-semibold leading-6 text-gray-900 mb-8">Notícias</p>
                                            <News news={states.dataNews} states={states} setters={setters} />
                                        </div>
                                    </div>
                                ) : (
                                    /* Nao encontrado - menssage */
                                    <div className="px-4 sm:px-6 lg:px-8 p-8  ">
                                        <div className="px-4 sm:px-6 lg:px-8 border rounded-[10px] shadow p-8">
                                            <p className="text-center text-xl font-semibold text-gray-900">Nenhuma notícia encontrada</p>
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}


export default DashBoard